import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';

@Component({
	selector: 'findqo-post-ad-dialog',
	templateUrl: './post-ad-dialog.component.html',
	styleUrls: ['./post-ad-dialog.component.scss'],
})
export class PostAdDialogComponent {
	constructor(private dailogRef: MatDialogRef<PostAdDialogComponent>, private router: Router) {}

	goToPlaceAd(): void {
		this.router.navigate([APP_ROUTE.placeAd]);
		this.dailogRef.close();
	}

	close(): void {
		this.dailogRef.close();
	}
}
