import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { FeatureVersion } from '@core/enums';
import { LocaleType } from '@core/enums/locale.enum';
import { CommonFacade } from '@core/facades/common.facade';
import { ClientUser, Section } from '@core/models';
import { Translations } from '@core/models/translations.model';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { ViewportService } from '@core/services/viewport.service';
import { environment } from '@environments/environment';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ButtonType } from '@shared/components/button/button.component';
import { Observable } from 'rxjs';
import { LogoutDialogComponent } from '../components/logout-dialog/logout-dialog.component';
import { HeaderFacade } from '../header.facade';
import { HEADER_ACCOUNT_NAV_LIST } from '../header.routes';

const LANGUAGE_NAVS = [
	{
		locale: LocaleType.ENGLISH,
		value: 'English',
	},
	{
		locale: LocaleType.KURDISTAN,
		value: 'کوردی',
	},
	{
		locale: LocaleType.ARABIC,
		value: 'عربى',
	},
];

@Component({
	template: '',
})
export abstract class AbstractHeaderComponent implements OnInit {
	public readonly headerFacade = inject(HeaderFacade);
	public readonly dialog = inject(MatDialog);
	public readonly router = inject(Router);
	public readonly route = inject(ActivatedRoute);
	public readonly viewportService = inject(ViewportService);
	public readonly offcanvas = inject(NgbOffcanvas);
	public readonly commonFacade = inject(CommonFacade);
	public readonly bpoService = inject(BreakpointObserverService);

	@Input() sections?: Section[]; // Desktop only
	@Input() locale: LocaleType;
	@Input() translations: Translations;
	@Input() user: any;

	sectionList$: Observable<Section[]>;
	user$: Observable<ClientUser>;

	readonly localeType = LocaleType;
	readonly languageNavs = LANGUAGE_NAVS;
	readonly isSellerModuleEnabled = environment.isSellerModuleEnabled;
	readonly buttonType = ButtonType;
	readonly featureVersion = FeatureVersion;
	readonly defaultAvatar = environment.defaultAvatarImg;
	readonly accountNavList = HEADER_ACCOUNT_NAV_LIST;
	readonly appRoute = APP_ROUTE;

	onLogout(): void {
		const TRANSLATIONS = this.commonFacade.getTranslations();

		const DIALOG_DATA = {
			title: TRANSLATIONS['n15'],
			message: TRANSLATIONS['n16'],
			confirmButton: TRANSLATIONS['n18'],
			cancelButton: TRANSLATIONS['n17'],
		};

		this.dialog
			.open(LogoutDialogComponent, { data: DIALOG_DATA, height: 'auto', width: 'auto', maxWidth: '353px' })
			.afterClosed()
			.subscribe((res) => {
				if (res) {
					this.headerFacade.logout();
					this.offcanvas.dismiss();
				}
			});
	}

	onLogin(): void {
		this.headerFacade.openAuthDialog();
	}

	onNavigate(url: string): void {
		if (!this.user) {
			this.onLogin();

			return;
		}

		if (this.viewportService.mobile) {
			this.offcanvas.dismiss();
		}

		this.router.navigate([url]);
	}

	redirectTo(url: string): void {
		if (this.viewportService.mobile) {
			this.offcanvas.dismiss();
		}

		this.router.navigate([url]);
	}

	onSelectLanguage(locale: LocaleType): void {
		this.headerFacade.setLocale(locale);
	}

	getCurrentRoute() {
		return this.router.url;
	}

	private initDeclarations(): void {
		this.sectionList$ = this.commonFacade.getSectionList$();
		this.user$ = this.headerFacade.getUser$();
	}

	ngOnInit(): void {
		this.initDeclarations();
	}
}
