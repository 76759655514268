<ng-container *ngIf="user$ | async as user">
	<button mat-icon-button class="menu-trigger" [matMenuTriggerFor]="accountMenu">
		<img class="avatar" width="40" height="40" alt="user profile picture" [ngSrc]="user?.picture ? user.picture : defaultAvatar" />

		<span class="text-body-regular fg-general-4 text-capitalize text-truncate">{{ user.name }}</span>

		<mat-icon svgIcon="arrow-drop-down"></mat-icon>
	</button>

	<mat-menu #accountMenu="matMenu" class="mt-2">
		<div class="menu">
			<div class="border-bottom">
				<a *ngFor="let nav of user | navMenuItems" mat-menu-item [routerLink]="nav.path">
					<img *ngIf="nav.data.iconImage" [ngSrc]="'assets/images/' + nav.data.iconImage + '.svg'" width="24" height="24" alt="business profile" class="me-2" />

					<mat-icon class="material-icons-outlined fg-general-4" *ngIf="nav.data.icon">{{ nav.data.icon }}</mat-icon>

					<span class="text-body-regular fg-general-5">{{ nav.data.title | translations }}</span>
					<span *ngIf="nav.data?.isNew" class="bg-prim-2 px-2 rounded fg-basic-1 text-caption-medium ms-2">NEW!</span>
				</a>
			</div>

			<button mat-menu-item (click)="onLogout()">
				<mat-icon class="material-icons-outlined fg-general-4">logout</mat-icon>
				<span class="text-body-regular fg-general-5">{{ 'n14' | translations }}</span>
			</button>
		</div>
	</mat-menu>
</ng-container>
